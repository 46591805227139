$white: rgba(241, 241, 241, 1);
$dark: rgba(26, 34, 44, 1);
$red: rgba(224, 85, 90, 1);

/* Body - default
   ========================================================================== */
body,
html {
  min-width: 400px;
  margin: 0;
  padding: 0;
  font-family: Verdana, Helvetica, Arial, sans-serif;
  background-image: url("/img/background.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center bottom;
  background-size: cover;
  background-color: $dark;
  color: $white;
  outline: none;
}

* {
  box-sizing: border-box;
}

a {
  color: $white;
  text-decoration: none;
  transition: color 0.2s ease;
  cursor: pointer;
}

:focus {outline:none;}
::-moz-focus-inner {border:0;}

/* Header
   ========================================================================== */
header {
  min-width: 400px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $dark;
  height: 3rem;
  z-index: 100;

  .wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    max-width: 1200px;
  }

  .brand {
    font-size: 2rem;
    font-weight: 400;
    float: left;

    img {
      height: 2.5rem;
      margin: 0.25rem 0;
      padding: 0;
      vertical-align: middle;
    }

    &:hover {
      color: $red;
    }
  }

  nav {
    height: 3rem;

    a {
      display: inline-block;
      color: $white;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2.6rem;
      padding: 0.2rem 0.25rem;
      margin-right: 0.5rem;

      &::after {
        content: "";
        display: block;
        width: 0;
        margin: 0 auto;
        border-bottom: 0.2rem solid $red;
        transition: width 0.2s ease;
      }

      &:hover:after {
        width: 100%;
      }
    }
  }
}

/* Container
   ========================================================================== */
.container {
  min-height: 100vh;
}

/* Footer
   ========================================================================== */
footer {
  min-width: 400px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 1rem;
}
